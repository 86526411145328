import React, { FC } from 'react';

import LinkItem from 'common/LinkItem';

import { SkillsCardProps } from './models';

import './SkillsCard.scss';

const SkillsCard: FC<SkillsCardProps> = ({ icon, iconAltText, ctaLink, title, text }) => (
  <div className="skills__card">
    <img
      src={icon.url.trim() === '' ? icon.fallbackUrl : icon.url}
      className="skills__card-logo"
      alt={iconAltText}
    />
    <LinkItem
      link={ctaLink}
      linkClass="skills__card-link link link--black-white"
      title={title}
      hasIcon
      isLink
      showTitle
    />
    {text ? <p className="skills__card-desc caption">{text}</p> : null}
  </div>
);

export default SkillsCard;
