import React, { FC } from 'react';
import { Link } from 'gatsby';

import Image from 'common/Image';

import { ResponsiveBrandsProps } from './models';

const ResponsiveBrands: FC<ResponsiveBrandsProps> = ({ brand }) => (
  <li className="brands-carousel__col col col--no-gutters" key={brand.name}>
    <Link
      className="brands-carousel__single-brand"
      to={brand.brandPage[0]?.url}
      aria-label={brand.ariaLabel}
    >
      <Image imageData={brand.image} alt={brand.image?.altText || ''} />
    </Link>
  </li>
);

export default ResponsiveBrands;
