import React, { FC, useEffect, useRef, useState } from 'react';

import CustomCarousel from 'components/CustomCarousel';

import OurBrandsScrollerSmallCard from '../OurBrandsScrollerSmallCard';
import { OurBrandsScrollerMobileBoxProps } from './models';

import './OurBrandsScrollerMobileBox.scss';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const OurBrandsScrollerMobileBox: FC<OurBrandsScrollerMobileBoxProps> = ({
  activeBrand,
  brands,
  ourBrandsTagText,
  closeButtonText,
  brandPageLinkText,
  changeActiveBrandHandle,
}) => {
  const [indexOfSlide, setIndexOfSlide] = useState<any>();
  const modalRef = useRef(null);

  useEffect(() => {
    setIndexOfSlide(brands.findIndex((x) => x.name === activeBrand?.name));
  }, [activeBrand, brands]);

  if (indexOfSlide > -1) {
    document.body.style.overflow = 'hidden';
  } else if (indexOfSlide === -1) {
    document.body.style.overflow = 'visible';
  }

  const closeModalHandle = (e) => {
    if (e.target !== modalRef.current) {
      setIndexOfSlide(-1);
    }
    changeActiveBrandHandle(undefined);
  };

  const onCloseModalHandle = (close: number) => {
    setIndexOfSlide(close);
    changeActiveBrandHandle(undefined);
  };

  const handleIndex = (index) => {
    setIndexOfSlide(index);

    const items = document.querySelectorAll('.our-brands-scroller__item');

    items.forEach((item) => {
      item
        .querySelector('.our-brands-scroller__brand-title')
        ?.classList.remove('our-brands-scroller__brand-title--active');
    });

    const button = items[index].querySelector('.our-brands-scroller__brand-title');

    button?.classList.add('our-brands-scroller__brand-title--active');
  };

  return (
    <>
      {indexOfSlide > -1 ? (
        <div
          className="our-brands-scroller__pop-up"
          role="presentation"
          ref={modalRef}
          onClick={closeModalHandle}
        >
          <div
            className="our-brands-scroller__overlay"
            role="presentation"
            onClick={(e) => e.stopPropagation()}
          >
            <CustomCarousel
              infiniteLoop
              showArrows={false}
              showStatus={false}
              showThumbs={false}
              selectedItem={indexOfSlide}
              showIndicators={false}
              onChange={handleIndex}
            >
              {brands?.map((brand) => (
                <OurBrandsScrollerSmallCard
                  brand={brand}
                  ourBrandsTagText={ourBrandsTagText}
                  closeButtonText={closeButtonText}
                  brandPageLinkText={brandPageLinkText}
                  closeModalHandle={onCloseModalHandle}
                  key={brand.name}
                />
              ))}
            </CustomCarousel>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default OurBrandsScrollerMobileBox;
