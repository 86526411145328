import React, { FC } from 'react';

import LinkItem from 'common/LinkItem';

import { InfoBlockProps } from './models';

import './InfoBlock.scss';

const InfoBlock: FC<InfoBlockProps> = ({ infoBlock }) => {
  if (
    !infoBlock ||
    (!infoBlock.tag && !infoBlock.text && (!infoBlock.ctaLink || !infoBlock.ctaLink.length))
  )
    return null;

  const { tag, text, ctaLink } = infoBlock;

  return (
    <div className="switcher-block__block-info">
      {tag ? <h2 className="switcher-block__block-tag">{infoBlock.tag}</h2> : null}
      {text ? <p className="switcher-block__block-info-text">{infoBlock.text}</p> : null}
      <LinkItem
        link={ctaLink}
        linkClass="switcher-block__block-link link link--pink"
        titleClass="switcher-block__block-link-title"
        hasIcon
        isLink
        showTitle
      />
    </div>
  );
};

export default InfoBlock;
