import React, { FC } from 'react';

import { MapQuoteProps } from './models';

import './MapQuote.scss';

const MapQuote: FC<MapQuoteProps> = ({ text, authorName, jobTitle }) => (
  <div className="gpg-map__info-item">
    {text ? <q className="gpg-map__quote">{text}</q> : null}
    {authorName ? <span className="gpg-map__author tag--bold">{authorName}</span> : null}
    {jobTitle ? <span className="gpg-map__job tag">{jobTitle}</span> : null}
  </div>
);

export default MapQuote;
