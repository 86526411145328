import React, { FC, useEffect, useState } from 'react';

import { ModulesContent } from '../../../../@types/content/modules';
import OurBrandsScrollerSmallCard from '../OurBrandsScrollerSmallCard';
import { OurBrandsScrollerDesktopAndTabletBoxProps } from './models';

const OurBrandsScrollerDesktopAndTabletBox: FC<OurBrandsScrollerDesktopAndTabletBoxProps> = ({
  activeBrand,
  ourBrandsTagText,
  closeButtonText,
  brandPageLinkText,
}) => {
  const [brand, setBrand] = useState<ModulesContent.BrandType>();

  useEffect(() => {
    if (activeBrand) {
      setBrand(activeBrand);
    }
  }, [activeBrand]);

  return (
    <OurBrandsScrollerSmallCard
      brand={brand}
      ourBrandsTagText={ourBrandsTagText}
      closeButtonText={closeButtonText}
      brandPageLinkText={brandPageLinkText}
    />
  );
};

export default OurBrandsScrollerDesktopAndTabletBox;
