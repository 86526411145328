import React, { FC, useContext, useMemo, useRef, useState } from 'react';

import { Col, Container, Row } from 'layout';
import LinkItem from 'common/LinkItem';
import { arraySplitter, getCenter, maxDifference } from 'utils/arraysHandler';

import { ModulesContent } from '../../@types/content/modules';
import Accordion from '../../common/AccordionContainer/Accordion/Accordion';
import AccordionContainer from '../../common/AccordionContainer/AccordionContainer';
import ButtonGrid from '../../common/TabGrid/ButtonGrid/ButtonGrid';
import TabGrid from '../../common/TabGrid/TabGrid';
import ScreenRecognitionContext from '../../contexts/screenRecognitionContext';
import GoogleMapComponent from '../GoogleMap/GoogleMap';
import { GlobalReachMapProps } from './models';

import './GlobalReachMap.scss';

const GlobalReachMap: FC<GlobalReachMapProps> = ({ module }) => {
  const { isMobile, isSmallMobile } = useContext(ScreenRecognitionContext);
  const [brands, setBrands] = useState(module.locations[0].children);
  const infoContainerRef = useRef<HTMLDivElement>(null);

  const handleToggleInfo = (id) => {
    const isActive = brands.find((brand) => brand.id === id)?.active;

    if ((isMobile || isSmallMobile) && !isActive) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }

    if (!isActive) {
      const offset = infoContainerRef.current?.offsetTop;
      window.scrollTo(0, (offset || 0) - 30);
    }

    setBrands((prevState) =>
      prevState.map((item) => ({
        ...item,
        active: item.id === id ? !item.active : false,
      }))
    );
  };

  const brandsLat = brands.map((brand) => brand.lat);
  const brandsLng = brands.map((brand) => brand.lng);

  const center = getCenter(brandsLat, brandsLng);

  const maxLat = maxDifference(brandsLat);
  const maxLng = maxDifference(brandsLng);
  const totalMax = Math.max(maxLat, maxLng);

  const splitBrandList: ModulesContent.LocationItemType[][] = useMemo(
    () => arraySplitter(brands, 6),
    [brands]
  );

  const zoom =
    totalMax < 1
      ? 8
      : totalMax < 4
      ? 7
      : totalMax < 8
      ? 6
      : totalMax < 16
      ? 5
      : totalMax < 24
      ? 4
      : totalMax < 32
      ? 3
      : 2;

  return (
    <section className="map section">
      <Container>
        <Row>
          <Col className="map__info col--no-gutters" xl="5">
            <h2 className="h4">{module.title}</h2>
            <p className="map__info-desc">{module.description}</p>
            <LinkItem
              link={module.ctaLink}
              linkClass="map__info-link link link--black-white"
              titleClass="map__info-link-text"
              hasIcon
              isLink
              showTitle
            />
          </Col>
          <Col className="col--no-gutters" xl="7">
            <div ref={infoContainerRef} className="map__map-container">
              <GoogleMapComponent
                center={center}
                brands={brands}
                toggleInfo={handleToggleInfo}
                zoom={zoom}
                containerRef={infoContainerRef}
                locationsTagText={module.locationsTagText}
                closeButtonText={module.closeButtonText}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="map__controls">
              <AccordionContainer showIndex={false}>
                <Accordion
                  title={module.locationsListTitle}
                  titleFont="h5"
                  btnTextExpand={module.expandButtonText}
                  btnTextCollapse={module.collapseButtonText}
                  scrollTo={false}
                >
                  <TabGrid itemList={splitBrandList}>
                    <ButtonGrid
                      itemList={splitBrandList}
                      baseClass="map__controls"
                      handleToggleInfo={handleToggleInfo}
                    />
                  </TabGrid>
                </Accordion>
              </AccordionContainer>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default GlobalReachMap;
