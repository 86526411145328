import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from 'react-google-maps';
import FocusTrap from 'focus-trap-react';
import { compose, withHandlers, withProps } from 'recompose';

import LinkItem from 'common/LinkItem';

import Plus from '../../common/icons/plus.svg';
import ScreenRecognitionContext from '../../contexts/screenRecognitionContext';
import { MapRefs } from './models';

const GoogleMapComponent = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyDUw4Io0r2Em43j5pb9r5XU0sx0W8giP-s&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div className="map__map" />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap,
  withHandlers(() => {
    const refs: MapRefs = {
      map: undefined,
      markers: [],
    };

    return {
      onMapMounted: () => (ref) => {
        refs.map = ref;
        refs.map?.panBy(0, -30);
      },
      onMarkerCreate: () => (ref) => {
        refs.markers?.push(ref);
      },
      panMap: () => (i, isMobile, isSmallMobile) => {
        refs.map?.panTo(refs.markers[i].getPosition());
        if (!isMobile && !isSmallMobile) refs.map?.panBy(-115, 23);
      },
    };
  })
)(
  ({
    center,
    brands,
    toggleInfo,
    zoom,
    containerRef,
    locationsTagText,
    closeButtonText,
    onMapMounted,
    onMarkerCreate,
    panMap,
  }) => {
    const { isMobile, isSmallMobile } = useContext(ScreenRecognitionContext);

    const Address = ({ address }: { address?: string }) =>
      address ? <address className="map__box-address caption">{address}</address> : null;

    return (
      <>
        <GoogleMap
          ref={onMapMounted}
          defaultZoom={zoom}
          defaultCenter={center}
          defaultOptions={{
            disableDefaultUI: true,
            zoomControl: true,
            restriction: {
              latLngBounds: { north: 85, south: -80, west: -180, east: 180 },
              strictBounds: true,
            },
            styles: [
              {
                featureType: 'water',
                elementType: 'geometry.fill',
                stylers: [
                  {
                    color: '#92b4f2',
                  },
                ],
              },
            ],
          }}
        >
          {brands.map((brand, i) => (
            <Marker
              ref={onMarkerCreate}
              icon={{
                url: brand.active ? '/icons/map-pin-active.svg' : '/icons/map-pin.svg',
                scaledSize: new google.maps.Size(25, 32),
              }}
              options={{
                zIndex: brand.active ? 100 : 0,
              }}
              onClick={() => toggleInfo(brand.id)}
              key={brand.id}
              position={{ lat: brand.lat, lng: brand.lng }}
              title={brand.name}
            >
              {brand.active
                ? ReactDOM.createPortal(
                    <div
                      className="map__modal"
                      onClick={() => toggleInfo(brand.id)}
                      aria-hidden="true"
                    >
                      <FocusTrap
                        focusTrapOptions={{
                          clickOutsideDeactivates: true,
                          returnFocusOnDeactivate: true,
                        }}
                      >
                        <div
                          className="map__box"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          aria-hidden="true"
                        >
                          {panMap(i, isMobile, isSmallMobile)}
                          <div className="map__box-header">
                            <span className="map__box-tag tag">{locationsTagText}</span>
                            <button
                              type="button"
                              className="map__box-close link link--black-white"
                              onClick={() => toggleInfo(brand.id)}
                            >
                              {closeButtonText}
                              <Plus className="map__box-close-icon" aria-hidden="true" />
                            </button>
                          </div>
                          <h3 className="map__box-title h6">{brand.title}</h3>
                          <Address address={brand.address} />
                          <LinkItem
                            link={brand.website}
                            linkClass="map__box-link link link--pink"
                            hasIcon
                            isLink
                            showTitle
                          />
                        </div>
                      </FocusTrap>
                    </div>,
                    containerRef.current
                  )
                : null}
            </Marker>
          ))}
        </GoogleMap>
      </>
    );
  }
);

export default GoogleMapComponent;
