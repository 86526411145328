import React, { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import { debounce } from 'lodash';

import { Col, Container, Row } from 'layout';
import { arraySplitter } from 'utils/arraysHandler';
import { isBrowser } from 'utils/browser';
import { DESKTOP_DEVICE, TABLET_DEVICE } from 'utils/constants';

import { SkillsProps } from './models';
import SkillsCard from './SkillsCard';

import './Skills.scss';

const Skills: FC<SkillsProps> = ({ module }) => {
  const [screen, setScreen] = useState<number | boolean>();
  const [rows, setRows] = useState<any>();

  const handleResize = debounce(() => {
    setScreen(window.innerWidth);
  }, 500);

  useEffect(() => {
    if (isBrowser()) {
      setScreen(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (!screen) return;

    if (screen < TABLET_DEVICE) {
      setRows(arraySplitter(module.boxes, 2));
    }

    if (screen >= TABLET_DEVICE && screen < DESKTOP_DEVICE) {
      setRows(arraySplitter(module.boxes, 3));
    }

    if (screen >= DESKTOP_DEVICE) {
      setRows(arraySplitter(module.boxes, 5));
    }
  }, [screen]);

  return (
    <section className="skills section">
      <Container>
        <div className="skills__container">
          {rows?.map((row) => (
            <Row element="ul" key={row[0].title} className="row--center">
              {row.map((card) => (
                <Col
                  md="4"
                  sm="6"
                  className={classNames('col--no-gutters', { 'col--center': row.length < 2 })}
                  key={card.title}
                  element="li"
                >
                  <SkillsCard {...card} />
                </Col>
              ))}
            </Row>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default Skills;
