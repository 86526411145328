import React, { FC } from 'react';
import classNames from 'classnames';

import Check from '../../../../common/icons/check.svg';
import UnCheck from '../../../../common/icons/uncheck.svg';
import { RestBrandsFiltersProps } from './models';

import './RestBrandsFilters.scss';

const RestBrandsFilters: FC<RestBrandsFiltersProps> = ({
  filter,
  filterBrandsHandle,
  activeFilter,
}) => (
  <>
    <button
      type="button"
      data-value={filter.name}
      onClick={filterBrandsHandle}
      className={classNames('brands-carousel__filter-btns link link--black-grey', {
        'filter-active': activeFilter === filter.name,
      })}
      disabled={activeFilter === filter.name}
    >
      {filter.tagName}
      {activeFilter === filter.name ? (
        <Check className="brands-carousel__icon" aria-hidden="true" />
      ) : (
        <UnCheck
          className="brands-carousel__icon brands-carousel__icon--uncheck"
          aria-hidden="true"
        />
      )}
    </button>
  </>
);

export default RestBrandsFilters;
