import React, { FC } from 'react';

import { Col, Row } from 'layout';
import LinkItem from 'common/LinkItem';

import { FactsHeadingAndTextProps } from './models';

import './FactsHeadingAndText.scss';

const FactsHeadingAndText: FC<FactsHeadingAndTextProps> = ({
  sectionTitle,
  title,
  description,
  link,
}) => {
  const Description = () => {
    if (!description) return null;

    return (
      <Col xl="7" md="8" sm="12" className="facts-with-tabs__info col--no-gutters">
        <div
          className="rte standfirst facts-with-tabs__description"
          dangerouslySetInnerHTML={{ __html: description }}
        />
        {link ? (
          <LinkItem
            link={link}
            linkClass="facts-with-tabs__link link link--white-white"
            isLink
            hasIcon
            showTitle
          />
        ) : null}
      </Col>
    );
  };

  return (
    <div className="facts-with-tabs__heading-text">
      <Row className="facts-with-tabs__heading-text-content">
        <Col xl="4" md="4" sm="12" className="col--no-gutters">
          <h2 className="facts-with-tabs__titles h2">
            <span className="facts-with-tabs__section-title">{sectionTitle}</span>
            {title}
          </h2>
        </Col>
        <Description />
      </Row>
    </div>
  );
};

export default FactsHeadingAndText;
