import React, { FC } from 'react';
import { Chart, GoogleChartWrapper } from 'react-google-charts';

import { MapRefs } from './models';

const options = {
  colorAxis: { minValue: 1, maxValue: 2, colors: ['#5A2882', '#FF007F'] },
  backgroundColor: '#E7E9EE',
  datalessRegionColor: '#c1c8cf',
  displayMode: 'regions',
  legend: 'none',
  tooltip: { trigger: 'none' },
};

const MapChart: FC<MapRefs> = ({ data, onSelect }) => {
  const handleSelect = (wrapper: GoogleChartWrapper) => {
    const selection = wrapper.getChart().getSelection();

    if (!selection[0]) return;

    const { row } = selection[0];

    onSelect(row + 1);
  };

  return (
    <Chart
      chartType="GeoChart"
      style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }}
      data={data}
      options={options}
      // @ts-ignore
      chartVersion="46"
      chartEvents={[
        {
          eventName: 'select',
          callback: ({ chartWrapper }) => {
            handleSelect(chartWrapper);
          },
        },
      ]}
    />
  );
};

export default MapChart;
