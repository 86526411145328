import React, { FC, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';

import { Col, Container, Row } from 'layout';
import { isBrowser } from 'utils/browser';
import { commonColorHandler } from 'utils/colorsHandler';
import { FLOCKLER_SITE_ID } from 'utils/constants';

import { embedInterface, SocialFeedProps } from './models';

import './SocialFeed.scss';

const SocialNetworkDictoinary = {
  Instagram: 'instagram',
  LinkedIn: 'linkedin',
  YouTube: 'youtube',
  Facebook: 'facebook',
  Twitter: 'twitter',
};

const SocialFeed: FC<SocialFeedProps> = ({
  module: {
    layoutId,
    title,
    hashtag,
    availableFeedTabsCheckbox,
    defaultFeed,
    backgroundColor,
    loadMoreButtonText,
    readMoreText,
  },
}) => {
  const [activeFilter, setActiveFilter] = useState<string>(
    defaultFeed && defaultFeed.length ? defaultFeed[0] : ''
  );
  const [embed, setEmbed] = useState<embedInterface | null>(null);

  useEffect(() => {
    const overrideTag = document.createElement('script');
    overrideTag.id = 'overrideTagScript';
    overrideTag.innerHTML = `var _flocklerOverride = _flocklerOverride || {}; _flocklerOverride['${layoutId}'] = {count: window.innerWidth < 768 ? 3 : window.innerWidth > 1200 ? 15 : 6,
      loadMoreText: '${loadMoreButtonText}',
      showMoreText: '${readMoreText}',
      ${hashtag ? `tags: ['${hashtag}']}` : '}'}`;
    const secondScriptTag = document.getElementsByTagName('script')[0];

    secondScriptTag.parentNode?.insertBefore(overrideTag, secondScriptTag);

    const tag = document.createElement('script');
    tag.src = `https://plugins.flockler.com/embed/${FLOCKLER_SITE_ID}/${layoutId}?autoload=false`;
    tag.async = true;
    tag.id = 'flocklerScript';
    tag.addEventListener('load', () => {
      const interval = setInterval(() => {
        if (isBrowser()) {
          if ((window as any).flcklr.Embeds) {
            setEmbed(
              (window as any).flcklr.Embeds.create(
                (window as any).flcklr.EmbedConfigs[layoutId],
                ((window as any).flcklr.EmbedConfigs[layoutId].only = activeFilter)
              )
            );
            clearInterval(interval);
          }
        }
      }, 100);
    });

    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode?.insertBefore(tag, firstScriptTag);

    return () => {
      embed?.destroy();
      const existing = document.getElementById('flocklerScript');
      if (existing) {
        existing.remove();
      }

      const existing2 = document.getElementById('overrideTagScript');
      if (existing2) {
        existing2.remove();
      }
    };
  }, []);

  const handleFilterClick = useCallback(
    (socialmedia: string) => {
      (window as any).flcklr.EmbedConfigs[layoutId].only =
        activeFilter === SocialNetworkDictoinary[socialmedia]
          ? ''
          : SocialNetworkDictoinary[socialmedia];

      setActiveFilter((prev) =>
        prev === SocialNetworkDictoinary[socialmedia] ? '' : SocialNetworkDictoinary[socialmedia]
      );

      embed?.destroy();
      setEmbed((window as any).flcklr.Embeds.create((window as any).flcklr.EmbedConfigs[layoutId]));
    },
    [activeFilter, embed]
  );

  return (
    <section
      className={classNames(`social-feed ${commonColorHandler(backgroundColor[0])}`, {
        section: commonColorHandler(backgroundColor[0]) === '',
        'section-bg': commonColorHandler(backgroundColor[0]) !== '',
      })}
    >
      <Container>
        {title ? <h2 className="social-feed__title h2">{title}</h2> : null}
        {!availableFeedTabsCheckbox ? null : (
          <Row className="social-feed__filters row--center">
            <ul className="social-feed__filters-container" role="tablist">
              {availableFeedTabsCheckbox.map((filter) => (
                <li role="presentation" key={filter}>
                  <button
                    className={classNames('social-feed__filter-btn btn', {
                      'btn--outline': SocialNetworkDictoinary[filter] !== activeFilter,
                      'btn--pink':
                        SocialNetworkDictoinary[filter] === activeFilter &&
                        commonColorHandler(backgroundColor[0]) !== 'social-feed--gradient',
                      'btn--white':
                        SocialNetworkDictoinary[filter] === activeFilter &&
                        commonColorHandler(backgroundColor[0]) === 'social-feed--gradient',
                      'yellow-outline':
                        commonColorHandler(backgroundColor[0]) === 'social-feed--gradient',
                    })}
                    type="button"
                    role="tab"
                    aria-selected={SocialNetworkDictoinary[filter] === activeFilter}
                    aria-controls="social-feed"
                    id={`social-${SocialNetworkDictoinary[filter]}`}
                    key={filter}
                    onClick={() => handleFilterClick(filter)}
                  >
                    {filter}
                  </button>
                </li>
              ))}
            </ul>
          </Row>
        )}
        <div id="social-feed" aria-labelledby={`social-${activeFilter}`}>
          <Row className="social-feed__feed">
            <Col className="col--no-gutters">
              <div id={`flockler-embed-${layoutId}`} />
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default SocialFeed;
