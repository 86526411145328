import React, { FC, useCallback, useEffect, useState } from 'react';

import { Col, Container, Row } from 'layout';
import { isBrowser } from 'utils/browser';
import { ALL, DESKTOP_DEVICE } from 'utils/constants';

import { ModulesContent } from '../../@types/content/modules';
import { arraySplitter } from '../../utils/arraysHandler';
import BrandsFilters from './BrandsFilters';
import { BrandsCarouselProps } from './models';
import ResponsiveBrands from './ResponsiveBrands';
import { arraySpliterResponsive, filterBrandsForSelectedFilter } from './utils';

import './BrandsCarousel.scss';

const BrandsCarousel: FC<BrandsCarouselProps> = ({ module }) => {
  const [activeFilter, setActiveFilter] = useState<string>(ALL);
  const [screen, setScreen] = useState<number | boolean>();
  const [rows, setRows] = useState<ModulesContent.BrandType[][]>([]);

  const allBrands: ModulesContent.BrandType[] =
    !!module.brands && module.brands.length ? module.brands[0]?.children : [];

  useEffect(() => {
    if (isBrowser()) {
      setScreen(window.innerWidth);
    }
  }, []);

  useEffect(() => {
    if (!screen) return;

    const active = module.brandsFilter ? module.brandsFilter[0]?.name : ALL;

    setActiveFilter(active);

    if (active === ALL) {
      const newArrayBrands: ModulesContent.BrandType[][] = arraySpliterResponsive(
        allBrands,
        screen
      );
      setRows(newArrayBrands);
    } else {
      const filteredBrands: ModulesContent.BrandType[] = filterBrandsForSelectedFilter(
        active,
        allBrands,
        module.brandsFilter
      );
      const setNewRow: ModulesContent.BrandType[][] = arraySpliterResponsive(
        filteredBrands,
        screen
      );
      setRows(setNewRow);
    }
  }, [screen]);

  const filterBrandsHandle = (e) => {
    const filterValue = e.currentTarget.dataset.value;
    let newFilter = activeFilter;

    if (!screen) return;

    if (filterValue === ALL) {
      setActiveFilter(ALL);
      const newRows = arraySpliterResponsive(allBrands, screen);
      setRows(newRows);
    } else {
      newFilter = filterValue;
      const filteredBrands: ModulesContent.BrandType[] = filterBrandsForSelectedFilter(
        newFilter,
        allBrands,
        module.brandsFilter
      );
      let setNewRow: ModulesContent.BrandType[][] = [[]];

      if (screen < DESKTOP_DEVICE) {
        setNewRow = arraySpliterResponsive(filteredBrands, screen);
      } else {
        setNewRow = arraySplitter(
          filteredBrands,
          Math.round(filteredBrands.length < 5 ? 4 : filteredBrands.length / 2)
        );
      }

      setActiveFilter(newFilter);

      setRows(setNewRow);
    }
  };

  const Brands = useCallback(
    () => (
      <ul className="row brands-carousel__cards-row brands-carousel__cards-row--responsive">
        {rows?.map((row) =>
          row.map((brand) => <ResponsiveBrands brand={brand} key={brand.name} />)
        )}
      </ul>
    ),
    [activeFilter, rows]
  );

  return (
    <section className="brands-carousel section">
      <Container>
        <Row>
          <Col xl="12" md="12" sm="12" className="brands-carousel__col-filters col--no-gutters">
            <BrandsFilters
              filterBrandsHandle={filterBrandsHandle}
              activeFilter={activeFilter}
              allBrandsText={module.allBrandsText}
              filters={module.filters}
              brandsFilter={module.brandsFilter}
            />
          </Col>
        </Row>
        <Brands />
      </Container>
    </section>
  );
};
export default BrandsCarousel;
