import React, { FC, useContext, useMemo } from 'react';
import { Link } from '@reach/router';
import { motion } from 'framer-motion';

import { Col } from 'layout';
import Image from 'common/Image';

import Arrow from '../../../../common/icons/arrow-right.svg';
import Close from '../../../../common/icons/close.svg';
import ScreenRecognitionContext from '../../../../contexts/screenRecognitionContext';
import { OurBrandsScrollerSmallCardProps } from './models';

import './OurBrandsScrollerSmallCard.scss';

const OurBrandsScrollerSmallCard: FC<OurBrandsScrollerSmallCardProps> = ({
  brand,
  ourBrandsTagText,
  closeButtonText,
  brandPageLinkText,
  closeModalHandle,
}) => {
  const { isDesktop, isLargeDesktop, isMobile, isSmallMobile } =
    useContext(ScreenRecognitionContext);

  const closeHandle = () => {
    if (closeModalHandle) {
      closeModalHandle(-1);
    }
  };

  const tagElement = useMemo(() => {
    if (!brand) return null;

    return (
      <>
        <div className="our-brands-scroller__tag-wrapp">
          <span className="our-brands-scroller__tag tag">{ourBrandsTagText}</span>
          {isMobile || isSmallMobile ? (
            <button
              className="our-brands-scroller__close-btn link"
              onClick={closeHandle}
              type="button"
            >
              <span className="our-brands-scroller__close-text">{closeButtonText}</span>
              <Close />
            </button>
          ) : null}
        </div>
        <Image
          imageData={brand.image}
          alt={brand.image.altText}
          className="our-brands-scroller__logo"
        />
        {isMobile || isSmallMobile ? (
          <h3 className="our-brands-scroller__card-title h6">{brand.name}</h3>
        ) : null}
        <p className="our-brands-scroller__desc tag">{brand.infoText}</p>
        {!isDesktop && !isLargeDesktop ? (
          <Link
            className="our-brands-scroller__link-card link link--pink"
            to={brand.brandPage[0].url}
            onClick={() => {
              document.body.style.overflow = 'auto';
            }}
          >
            <span className="our-brands-scroller__link-text">{brandPageLinkText}</span>
            <Arrow className="our-brands-scroller__link-icon" />
          </Link>
        ) : null}
      </>
    );
  }, [brand]);

  return (
    <>
      {brand ? (
        <Col className="our-brands-scroller__card col--no-gutters">
          <div className="our-brands-scroller__details" key={brand.name}>
            {!isMobile && !isSmallMobile ? (
              <Image
                imageData={brand.infoImage}
                alt={brand.infoImage.altText}
                className="our-brands-scroller__img"
              />
            ) : null}
            {!isMobile && !isSmallMobile ? (
              <motion.div
                className="our-brands-scroller__small-card"
                key={brand.name}
                initial={{ y: 60 }}
                animate={{ y: 0 }}
                exit={{ y: 60 }}
                transition={{ duration: 0.5 }}
              >
                {tagElement}
              </motion.div>
            ) : (
              <div className="our-brands-scroller__small-card">{tagElement}</div>
            )}
          </div>
        </Col>
      ) : null}
    </>
  );
};

export default OurBrandsScrollerSmallCard;
