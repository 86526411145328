import React, { FC, useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';

import { Col, Container, Row } from 'layout';
import { isBrowser } from 'utils/browser';
import { ALL, DESKTOP_DEVICE, TABLET_DEVICE } from 'utils/constants';
import { handleSkipSection } from 'utils/functions';

import { ModulesContent } from '../../@types/content/modules';
import Arrow from '../../common/icons/arrow-right.svg';
import { filterBrandsForSelectedFilter } from '../BrandsCarousel/utils';
import { OurBrandsScrollerProps } from './models';
import OurBrandsScrollerBottom from './OurBrandsScrollerBottom';
import OurBrandsScrollerFilters from './OurBrandsScrollerFilters';

import './OurBrandsScroller.scss';

const OurBrandsScroller: FC<OurBrandsScrollerProps> = ({ module }) => {
  const [activeFilter, setActiveFilter] = useState<string>(ALL);
  const [screen, setScreen] = useState<number | boolean>();
  const [brands, setBrands] = useState<ModulesContent.BrandType[]>([]);

  const sectionRef = useRef<HTMLTableSectionElement>(null);

  const allBrands = module.brands[0].children;

  const handleResizeFilter = debounce(() => {
    setScreen(window.innerWidth);
  }, 100);

  useEffect(() => {
    if (isBrowser()) {
      setScreen(window.innerWidth);
    }

    window.addEventListener('resize', handleResizeFilter);

    return () => window.removeEventListener('resize', handleResizeFilter);
  }, []);

  useEffect(() => {
    if (!screen) return;

    const active = screen < TABLET_DEVICE ? module.filters[0].name : ALL;
    setActiveFilter(active);

    if (active === ALL) {
      setBrands(module.brands[0].children);
    }

    if (active === module.filters[0].name) {
      const filteredBrands = filterBrandsForSelectedFilter(active, allBrands);
      setBrands(filteredBrands);
    }
  }, [screen]);

  const onFilterBrandsHandle = (e) => {
    const filterValue = e.currentTarget.dataset.value;
    let newFilter = activeFilter;

    if (!screen) return;

    if (filterValue === ALL) {
      setActiveFilter(ALL);
      setBrands(module.brands[0].children);
    } else {
      newFilter = filterValue;
      const filteredBrands = filterBrandsForSelectedFilter(newFilter, allBrands);

      setActiveFilter(newFilter);
      setBrands(filteredBrands);
    }
  };

  const SkippSection = () => {
    if (screen && screen < DESKTOP_DEVICE) return null;

    return (
      <button
        className="our-brands-scroller__link-skip link link--download"
        onClick={() => handleSkipSection(sectionRef)}
        type="button"
      >
        {module.skipSectionText}
        <Arrow className="our-brands-scroller__icon-skip" aria-hidden="true" />
      </button>
    );
  };

  return (
    <section className="our-brands-scroller section-bg" ref={sectionRef}>
      <Container>
        <div className="our-brands-scroller__background" />
        <Row>
          <Col xl="12" md="12" sm="12" className="our-brands-scroller__col-content col--no-gutters">
            <div className="our-brands-scroller__filters">
              <OurBrandsScrollerFilters
                activeFilter={activeFilter}
                filterAll={module.allBrandsText}
                filtersName={module.filterBrandsText}
                filters={module.filters}
                filterBrandsHandle={onFilterBrandsHandle}
              />
            </div>
            <SkippSection />
            <OurBrandsScrollerBottom
              brands={brands}
              screen={screen}
              activeFilter={activeFilter}
              readMoreText={module.readMoreText}
              ourBrandsTagText={module.ourBrandsTagText}
              closeButtonText={module.closeButtonText}
              brandPageLinkText={module.brandPageLinkText}
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default OurBrandsScroller;
