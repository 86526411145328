import React, { FC } from 'react';
import classNames from 'classnames';

import { Col, Container, Row } from 'layout';
import Image from 'common/Image';
import { commonColorHandler } from 'utils/colorsHandler';

import { TwoRowsStatsAndContentProps } from './models';

import './TwoRowsStatsAndContent.scss';

const TwoRowsStatsAndContent: FC<TwoRowsStatsAndContentProps> = ({ module }) => (
  <section
    className={classNames(`two-rows-stats ${commonColorHandler(module.backgroundColor[0])}`, {
      section: !commonColorHandler(module.backgroundColor[0]),
      'section-bg': !!commonColorHandler(module.backgroundColor[0]),
    })}
  >
    <Container>
      <h2 className="two-rows-stats__title">
        <span className="two-rows-stats__tag h6">{module.sectionTag}</span>
        <span className="h2">{module.title}</span>
      </h2>
      {module.items?.map((item) => (
        <Row
          key={item.infoBlockTitle + item.statistics}
          className={classNames('two-rows-stats__item', {
            'two-rows-stats__item--right': item.isStatisticsOnRight,
          })}
        >
          <Col md="6" xl="5" className="two-rows-stats__left col--no-gutters">
            <span className="two-rows-stats__stat">{item.statistics}</span>
            <div
              className="two-rows-stats__info h6 rte"
              dangerouslySetInnerHTML={{ __html: item.statisticsSupportingText }}
            />
          </Col>
          <Col md="6" className="two-rows-stats__right col--no-gutters">
            <h3 className="two-rows-stats__item-title h4">{item.infoBlockTitle}</h3>
            <p>{item.infoBlockText}</p>
          </Col>
        </Row>
      ))}
      {module.image ? (
        <Image
          className="two-rows-stats__img"
          imageData={module.image}
          alt={module.image.altText}
        />
      ) : null}
    </Container>
  </section>
);

export default TwoRowsStatsAndContent;
