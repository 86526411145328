import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';

import { Col, Container, Row } from 'layout';
import { commonColorHandler } from 'utils/colorsHandler';

import FactsHeadingAndText from './FactsHeadingAndText';
import FactsStats from './FactsStats';
import { FactsWithTabsProps } from './models';

import './FactsWithTabs.scss';

const FactsWithTabs: FC<FactsWithTabsProps> = ({ module }) => {
  const [activeTabTitle, setActiveTabTitle] = useState<string>('');
  const containerRef = useRef<HTMLDivElement>(null);
  const itemsRef = useRef<HTMLButtonElement[]>([]);
  const [, setSelectedIndex] = useState(0);

  useEffect(() => {
    if (!module.tabs) return;

    setActiveTabTitle(module.tabs[0].title);
  }, []);

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, module.tabs.length);
  }, [module.tabs]);

  const setIndex = (index) => {
    setSelectedIndex(index);
    const tab = itemsRef.current[index];
    if (tab) tab.focus();
  };

  const onKeyDown = (e, index) => {
    const count = module.tabs.length;
    const nextTab = () => setIndex((index + 1) % count);
    const prevTab = () => setIndex((index - 1 + count) % count);
    const firstTab = () => setIndex(0);
    const lastTab = () => setIndex(count - 1);

    const keyMap = {
      ArrowRight: nextTab,
      ArrowLeft: prevTab,
      Home: firstTab,
      End: lastTab,
    };
    const action = keyMap[e.key];
    if (action) {
      e.preventDefault();
      action();
    }
  };

  const handleTabClick = (title: string, index: number) => {
    const filterContainer = containerRef.current?.firstChild;
    setActiveTabTitle(title);
    setSelectedIndex(index);
    containerRef.current?.scrollIntoView();
    if (index > 3) {
      (filterContainer as HTMLElement).scrollLeft += 170;
    } else {
      (filterContainer as HTMLElement).scrollLeft -= 170;
    }
  };

  const filterButtons = useMemo(
    () =>
      module.tabs?.map(({ title }, index) => (
        <button
          type="button"
          key={title}
          className={classNames('facts-with-tabs__tab', {
            'facts-with-tabs__tab--active': title === activeTabTitle,
          })}
          onClick={() => handleTabClick(title, index)}
          id={title.replace(' ', '-')}
          role="tab"
          aria-controls={title.replace(' ', '-')}
          aria-selected={activeTabTitle === title}
          tabIndex={activeTabTitle === title ? 0 : -1}
          onKeyDown={(e) => onKeyDown(e, index)}
          ref={(el) => {
            itemsRef.current[index] = el!;
          }}
        >
          {title}
        </button>
      )),
    [module.tabs, activeTabTitle]
  );

  return (
    <section
      className={classNames('facts-with-tabs', commonColorHandler(module.backgroundColor?.[0]))}
    >
      <div ref={containerRef} className="facts-with-tabs__holder">
        <div className="facts-with-tabs__tabs-filter" role="tablist">
          {module.tabs?.length > 2 ? (
            <div className="facts-with-tabs__filter-container">
              <div className="facts-with-tabs__filter-overflow">{filterButtons}</div>
            </div>
          ) : (
            filterButtons
          )}
        </div>
        <Container>
          <div className="facts-with-tabs__content">
            {module.tabs?.map((tab) => (
              <div
                key={tab.title}
                aria-label={`${tab.title.replace(' ', '-')}-panel`}
                id={`${tab.title.replace(' ', '-')}-id`}
                role="tabpanel"
                aria-labelledby={tab.title.replace(' ', '-')}
              >
                {tab.title === activeTabTitle ? (
                  <>
                    <FactsHeadingAndText
                      sectionTitle={tab.sectionTitle}
                      title={tab.title}
                      description={tab.description}
                      link={tab.link}
                    />
                    <Row element="ul" className="facts-with-tabs__row">
                      {tab.facts.map(({ fact, text }) => (
                        <Col
                          key={fact + text}
                          sm="12"
                          md="6"
                          xl="3"
                          className="col--no-gutters facts-with-tabs__col"
                          element="li"
                        >
                          <FactsStats fact={fact} text={text} />
                        </Col>
                      ))}
                    </Row>
                  </>
                ) : null}
              </div>
            ))}
          </div>
        </Container>
      </div>
    </section>
  );
};

export default FactsWithTabs;
