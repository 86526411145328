import React, { FC } from 'react';

import GpgData from '../GpgData';
import MapImage from '../MapImage';
import MapQuote from '../MapQuote';
import MapText from '../MapText';
import { PopupInformationRendererProps } from './models';

const Components = {
  GenderPayGapData: GpgData,
  MapImage,
  MapQuote,
  MapText,
};

const PopupInformationRenderer: FC<PopupInformationRendererProps> = ({ items, country }) => (
  <>
    {items?.map((item, index) => {
      if (typeof Components[item.__typename] === 'undefined') {
        return null;
      }

      const Component = Components[item.__typename];

      return <Component key={`${country}${item.__typename}${index}`} {...item} />;
    })}
  </>
);

export default PopupInformationRenderer;
