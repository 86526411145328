import React, { FC, useContext } from 'react';
import classNames from 'classnames';

import { Col } from 'layout';
import Image from 'common/Image';
import LinkItem from 'common/LinkItem';

import ScreenRecognitionContext from '../../../contexts/screenRecognitionContext';
import { SdgItemProps } from './models';

import './SdgItem.scss';

const SdgItem: FC<SdgItemProps> = ({
  icon,
  goalNumber,
  title,
  description,
  ctaLink,
  numberOfCards,
}) => {
  const { isLargeDesktop, isDesktop, isTablet, isSmallMobile, isMobile } =
    useContext(ScreenRecognitionContext);

  const classForCol = classNames('brand-purpose-sdg__col col--no-gutters', {
    'brand-purpose-sdg__col--one-card': numberOfCards === 1,
    'brand-purpose-sdg__col--two-card': numberOfCards === 2,
    'brand-purpose-sdg__col--three-card': numberOfCards === 3,
  });

  return (
    <Col xl="4" md="4" sm="12" className={classForCol}>
      <div className={classNames('brand-purpose-sdg__card', { dasd: isMobile || isSmallMobile })}>
        {icon ? (
          <Image className="brand-purpose-sdg__icon" imageData={icon} alt={icon.altText} />
        ) : null}
        <div className="brand-purpose-sdg__bottom">
          {goalNumber ? (
            <span className="brand-purpose-sdg__span tag tag--bold">{goalNumber}</span>
          ) : null}
          <h3
            className={classNames('brand-purpose-sdg__title', {
              h6: isTablet,
              h5: isLargeDesktop || isDesktop,
            })}
          >
            {title}
          </h3>
          <p
            className={classNames('brand-purpose-sdg__desc', {
              caption: !isSmallMobile && !isMobile,
            })}
          >
            {description}
          </p>
          <LinkItem
            link={ctaLink}
            linkClass="brand-purpose-sdg__link link link--pink"
            titleClass="brand-purpose-sdg__text"
            hasIcon
            isLink
            showTitle
          />
        </div>
      </div>
    </Col>
  );
};

export default SdgItem;
