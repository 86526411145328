import React, { FC, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { debounce } from 'lodash';

import { GpgItemProps } from './models';

const GpgItem: FC<GpgItemProps> = ({
  text,
  womenPercentage,
  womenValueSuffix,
  menPercentage,
  menValueSuffix,
  hideBarChart,
  womenIconText,
  menIconText,
}) => {
  const minWidth = 10;
  const contentRef = useRef<HTMLDivElement>(null);
  const barRef = useRef<HTMLDivElement>(null);
  const valueRef = useRef<HTMLDivElement>(null);
  const [isCentered, setIsCentered] = useState(false);
  const [width, setWidth] = useState(minWidth);
  const [screenWidth, setScreenWidth] = useState<number>(0);
  const value = menPercentage !== 0 ? menPercentage : womenPercentage;
  const suffix = menPercentage !== 0 ? menValueSuffix : womenValueSuffix;

  useEffect(() => {
    if (!contentRef.current || !barRef.current || !valueRef.current) return;

    setWidth(Math.abs(((contentRef.current.clientWidth / 2 - minWidth) * value) / 100) + minWidth);
  }, [screenWidth]);

  useEffect(() => {
    if (!contentRef.current || !barRef.current || !valueRef.current) return;

    setIsCentered(
      contentRef.current.clientWidth / 2 <
        barRef.current.clientWidth + valueRef.current.clientWidth + minWidth
    );
  }, [width]);

  const handleResize = debounce(() => {
    setScreenWidth(window.innerWidth);
  }, 500);

  useEffect(() => {
    setScreenWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="gpg-map__graph">
      <span className="gpg-map__graph-title tag">{text}</span>
      <div className="gpg-map__graph-container">
        <div className="gpg-map__graph-icon gpg-map__graph-icon--blue tag--bold">
          {womenIconText}
        </div>
        {hideBarChart ? (
          <div className="gpg-map__graph-stats">
            <div className="gpg-map__graph-stat left">
              {`${womenPercentage}%`}
              {!womenValueSuffix ? null : (
                <span
                  dangerouslySetInnerHTML={{
                    __html: womenValueSuffix,
                  }}
                />
              )}
            </div>
            <div className="gpg-map__graph-stat right">
              {`${menPercentage}%`}
              {!menValueSuffix ? null : (
                <span
                  dangerouslySetInnerHTML={{
                    __html: menValueSuffix,
                  }}
                />
              )}
            </div>
          </div>
        ) : (
          <div
            ref={contentRef}
            className={classNames('gpg-map__graph-content', {
              negative: value < 0,
            })}
          >
            <div
              ref={barRef}
              className="gpg-map__graph-bar"
              style={{
                width,
              }}
            >
              <div
                ref={valueRef}
                className={classNames('gpg-map__graph-value tag--bold', {
                  'gpg-map__graph-value--center': isCentered,
                })}
              >
                {`${value}%`}
                {!suffix ? null : (
                  <div
                    className="gpg-map__graph-sign"
                    dangerouslySetInnerHTML={{
                      __html: suffix,
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        )}
        <div className="gpg-map__graph-icon gpg-map__graph-icon--purple tag--bold">
          {menIconText}
        </div>
      </div>
    </div>
  );
};

export default GpgItem;
