import { arraySplitter } from 'utils/arraysHandler';

import { ModulesContent } from '../../@types/content/modules';

export const arraySpliterResponsive = (
  array: ModulesContent.BrandType[],
  screen: number | boolean
): ModulesContent.BrandType[][] => {
  if (screen >= 1200) {
    return arraySplitter(array, 6);
  }

  if (screen >= 768 && screen < 1200) {
    return arraySplitter(array, 4);
  }

  if (screen < 768) {
    return arraySplitter(array, 2);
  }

  return [];
};

export const filterBrandsForSelectedFilter = (
  newFilter: string,
  allBrands: ModulesContent.BrandType[],
  brandsFilter?: ModulesContent.BrandCategoryType[]
): ModulesContent.BrandType[] =>
  allBrands.filter(
    (brand) =>
      newFilter ===
      (brand.subcategory && brandsFilter && brandsFilter[0].name !== newFilter
        ? brand.subcategory[0].name
        : brand.category[0].name)
  );
