import React, { FC } from 'react';

import { ModulesContent } from '../../../@types/content/modules';
import GpgItem from './GpgItem';
import GpgTextAndStat from './GpgTextAndStat';
import { GpgDataProps } from './models';

import './GpgData.scss';

const GpgData: FC<GpgDataProps> = ({ womenIconText, menIconText, items }) => (
  <>
    {items.map((item) => (
      <div className="gpg-map__info-item" key={item.text}>
        {item.__typename === 'GenderPayGapItem' ? (
          <GpgItem
            {...(item as ModulesContent.GenderPayGapItemType)}
            womenIconText={womenIconText}
            menIconText={menIconText}
          />
        ) : (
          <GpgTextAndStat {...(item as ModulesContent.GenderPayGapTextAndStatType)} />
        )}
      </div>
    ))}
  </>
);

export default GpgData;
