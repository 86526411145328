import React, { FC } from 'react';
import classNames from 'classnames';

import { ALL } from 'utils/constants';

import Check from '../../../common/icons/check.svg';
import UnCheck from '../../../common/icons/uncheck.svg';
import { BrandsFiltersProps } from './models';
import RestBrandsFilters from './RestBrandsFilters';

const BrandsFilters: FC<BrandsFiltersProps> = ({
  filterBrandsHandle,
  activeFilter,
  allBrandsText,
  filters,
  brandsFilter,
}) => {
  const allFilters = brandsFilter ? brandsFilter[0].name : ALL;

  return (
    <div className="brands-carousel__filter-container">
      <button
        type="button"
        data-value={allFilters}
        onClick={filterBrandsHandle}
        className={classNames('brands-carousel__filter-btns link link--black-grey', {
          'filter-active': activeFilter === allFilters,
        })}
        disabled={activeFilter === allFilters}
      >
        <span className="brands-carousel__filter-btns-text">{allBrandsText}</span>
        {activeFilter === allFilters ? (
          <Check className="brands-carousel__icon" aria-hidden="true" />
        ) : (
          <UnCheck
            className="brands-carousel__icon brands-carousel__icon--uncheck"
            aria-hidden="true"
          />
        )}
      </button>
      {filters?.map((filter) => (
        <RestBrandsFilters
          filter={filter}
          key={filter.name}
          filterBrandsHandle={filterBrandsHandle}
          activeFilter={activeFilter}
        />
      ))}
    </div>
  );
};
export default BrandsFilters;
