import React, { FC } from 'react';
import classNames from 'classnames';

import { BenefitsSimpleItemsProps } from './models';

import './BenefitsSimpleItems.scss';

const BenefitsSimpleItems: FC<BenefitsSimpleItemsProps> = ({ items, hideNumbers }) => (
  <div
    className={classNames('benefits__simple-items', {
      'benefits__simple-items--hide-number': hideNumbers,
      'benefits__simple-items--show-number': !hideNumbers,
    })}
  >
    {items?.map(({ title, text }, index) => (
      <div className="benefits__item" key={title}>
        <div className="benefits__item-inner">
          <h3 className="benefits__item-heading">
            <span className="benefits__number h1">
              {index + 1 < 10 ? `0${index + 1}` : index + 1}
            </span>
            <span className="h4">{title}</span>
          </h3>
          <p className="benefits__item-text body">{text}</p>
        </div>
      </div>
    ))}
  </div>
);

export default BenefitsSimpleItems;
