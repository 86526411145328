import React, { FC, ReactElement, useEffect, useRef, useState } from 'react';

import { TabGridProps } from './models';

const TabGrid: FC<TabGridProps> = ({ itemList, children }) => {
  const [activeItem, setActiveItem] = useState({
    focus: false,
    col: 0,
    row: 0,
  });
  const focusContainerRef = useRef<HTMLDivElement>(null);

  const handleKeyPress = (e) => {
    if (!activeItem.focus && e.keyCode !== 9)
      setActiveItem((prevState) => ({ ...prevState, focus: true }));
    if (e.keyCode === 40) {
      e.preventDefault();
      if (activeItem.row < itemList[activeItem.col].length - 1) {
        setActiveItem((prevState) => ({ ...prevState, row: prevState.row + 1 }));
      }
    }
    if (e.keyCode === 38) {
      e.preventDefault();
      if (activeItem.row > 0) {
        setActiveItem((prevState) => ({ ...prevState, row: prevState.row - 1 }));
      }
    }
    if (e.keyCode === 39) {
      e.preventDefault();
      if (activeItem.col < itemList.length - 1) {
        setActiveItem((prevState) => ({
          ...prevState,
          col: prevState.col + 1,
          row:
            prevState.row > itemList[prevState.col + 1].length - 1
              ? itemList[prevState.col + 1].length - 1
              : prevState.row,
        }));
      }
    }
    if (e.keyCode === 37) {
      e.preventDefault();
      if (activeItem.col > 0) {
        setActiveItem((prevState) => ({
          ...prevState,
          col: prevState.col - 1,
          row:
            prevState.row > itemList[prevState.col - 1].length - 1
              ? itemList[prevState.col - 1].length - 1
              : prevState.row,
        }));
      }
    }
  };

  const handleBlur = () => {
    setActiveItem((prevState) => ({ ...prevState, focus: false }));
  };

  const handleMouseClick = (colIndex, btnIndex) => {
    setActiveItem((prevState) => ({
      ...prevState,
      col: colIndex,
      row: btnIndex,
    }));
  };

  useEffect(() => {
    if (activeItem.focus)
      (focusContainerRef.current?.querySelector('[tabindex = "0"]') as HTMLElement)?.focus();
  }, [activeItem]);

  return (
    <div ref={focusContainerRef}>
      {React.Children.map(children, (child) => {
        const item = child as ReactElement;

        return (
          <item.type
            {...item.props}
            handleKeyPress={handleKeyPress}
            handleBlur={handleBlur}
            handleMouseClick={handleMouseClick}
            activeItem={activeItem}
          />
        );
      })}
    </div>
  );
};

export default TabGrid;
