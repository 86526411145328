import React, { FC } from 'react';
import classNames from 'classnames';

import { Container, Row } from 'layout';
import { arraySplitter } from 'utils/arraysHandler';

import { ModulesContent } from '../../@types/content/modules';
import { BrandPurposeAndSdgProps } from './models';
import SdgItem from './SdgItem';

import './BrandPurposeAndSdg.scss';

const BrandPurposeAndSdg: FC<BrandPurposeAndSdgProps> = ({ module }) => {
  const rows = arraySplitter(module.items, 3);

  return (
    <section
      className={classNames('brand-purpose-sdg', {
        'brand-purpose-sdg--grey section-bg': module.isBackgroundGrey,
        section: !module.isBackgroundGrey,
      })}
    >
      <Container>
        {rows.map((row: ModulesContent.BrandPurposeAndSdgItem[], i: number) => (
          <Row className={classNames({ 'row--center': row.length < 3 })} key={row.length + i}>
            {row.map((item: ModulesContent.BrandPurposeAndSdgItem) => (
              <SdgItem key={item.title} {...item} numberOfCards={row.length} />
            ))}
          </Row>
        ))}
      </Container>
    </section>
  );
};

export default BrandPurposeAndSdg;
