import React, { FC } from 'react';

import { Col, Row } from 'layout';

import { StatisticsBasicWithIconsProps } from './models';

import './StatisticsBasicWithIcons.scss';

const StatisticsBasicWithIcons: FC<StatisticsBasicWithIconsProps> = ({ stats }) => (
  <Row className="statistics-basic__stats-holder">
    {stats?.map(({ icon, iconAltText, statTitle, statDescription }) => (
      <Col
        xl="6"
        md="6"
        sm="12"
        key={iconAltText}
        className="statistics-basic__stats-item col--no-gutters"
      >
        <img
          src={icon.url.trim() === '' ? icon.fallbackUrl : icon.url}
          className="statistics-basic__stats-icon"
          alt={iconAltText}
        />
        {statTitle && <h3 className="statistics-basic__stats-title h6">{statTitle}</h3>}
        {statDescription && (
          <div
            className="statistics-basic__stats-rte rte"
            dangerouslySetInnerHTML={{ __html: statDescription }}
          />
        )}
      </Col>
    ))}
  </Row>
);

export default StatisticsBasicWithIcons;
