import React, { FC } from 'react';

import Image from 'common/Image';

import { MapImageProps } from './models';

import './MapImage.scss';

const MapImage: FC<MapImageProps> = ({ image }) => {
  if (!image) return null;

  return (
    <div className="gpg-map__info-item">
      <Image imageData={image} alt={image.altText} className="gpg-map__image" />
    </div>
  );
};

export default MapImage;
