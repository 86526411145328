import React, { FC } from 'react';

import { GpgTextAndStatProps } from './models';

const GpgTextAndStat: FC<GpgTextAndStatProps> = ({ text, stat, statSuffix }) => (
  <div className="gpg-map__data">
    <p className="tag">{text}</p>
    <div className="gpg-map__stat h5">
      {stat}
      {!statSuffix ? null : (
        <span
          dangerouslySetInnerHTML={{
            __html: statSuffix,
          }}
        />
      )}
    </div>
  </div>
);

export default GpgTextAndStat;
