import React, { FC, useState } from 'react';

import { Col } from 'layout';

import { ModulesContent } from '../../../@types/content/modules';
import { OurBrandsScrollerBottomProps } from './models';
import OurBrandsScrollerBrand from './OurBrandsScrollerBrand';

import './OurBrandsScrollerBottom.scss';

const OurBrandsScrollerBottom: FC<OurBrandsScrollerBottomProps> = ({
  brands,
  screen,
  readMoreText,
  ourBrandsTagText,
  closeButtonText,
  brandPageLinkText,
  activeFilter,
}) => {
  const [activeBrand, setActiveBrand] = useState<ModulesContent.BrandType>();

  const onBrandHoveredHandle = (brand: string) => {
    const selectedBrand = brands.filter((b) => b.name === brand)[0];
    setActiveBrand(selectedBrand);
  };

  const onChangeActiveBrandHandle = (brand: undefined) => {
    setActiveBrand(brand);
  };

  return (
    <>
      <Col xl="6" md="5" sm="12" className="our-brands-scroller__bottom col--no-gutters">
        <ul className="our-brands-scroller__list" id="our-brands-list">
          {brands?.map((brand) => (
            <li className="our-brands-scroller__item" key={brand.name}>
              <OurBrandsScrollerBrand
                brand={brand}
                brands={brands}
                activeBrand={activeBrand}
                screen={screen}
                activeFilter={activeFilter}
                readMoreText={readMoreText}
                ourBrandsTagText={ourBrandsTagText}
                closeButtonText={closeButtonText}
                brandPageLinkText={brandPageLinkText}
                brandHoveredHandle={onBrandHoveredHandle}
                onChangeActiveBrandHandle={onChangeActiveBrandHandle}
              />
            </li>
          ))}
        </ul>
      </Col>
    </>
  );
};

export default OurBrandsScrollerBottom;
