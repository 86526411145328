import React, { FC, useEffect, useRef, useState } from 'react';

import { Col, Container, Row } from 'layout';
import { isBrowser } from 'utils/browser';
import { TABLET_DEVICE } from 'utils/constants';

import MapChart from './Chart/Chart';
import { GpgMapProps } from './models';
import PopupInformationRenderer from './PopupInformationRenderer';

import './GpgMap.scss';

const GpgMap: FC<GpgMapProps> = ({ module }) => {
  const { countries, bottomText, nextCountryButtonLabel, previousCountryButtonLabel } = module;
  const [data, setData] = useState<(string | number)[][]>([['Country', '']]);
  const [active, setActive] = useState(0);
  const infoRef = useRef<HTMLDivElement>(null);
  const [screen, setScreen] = useState<number | boolean>();

  useEffect(() => {
    if (isBrowser()) {
      setScreen(window.innerWidth);
    }
  }, []);

  useEffect(() => {
    const newData = countries.reduce(
      (prev: (string | number)[][], child, i) => [...prev, [child.countryName, i === 0 ? 2 : 1]],
      [['Country', '']]
    );
    setData(newData);
  }, [countries]);

  const updateState = (index) => {
    setData((prev) =>
      prev.map((item, i) => (i > 0 ? (i === index ? [item[0], 2] : [item[0], 1]) : item))
    );
    setActive(index - 1);

    if (!screen) return;

    if (screen < TABLET_DEVICE) {
      infoRef.current?.scrollIntoView();
    }
  };

  const next = () => {
    if (active === data.length - 2) {
      setData((prev) =>
        prev.map((item, i) => (i > 0 ? (i === 1 ? [item[0], 2] : [item[0], 1]) : item))
      );
      setActive(0);
    } else {
      setData((prev) =>
        prev.map((item, i) => (i > 0 ? (i === active + 2 ? [item[0], 2] : [item[0], 1]) : item))
      );
      setActive((prev) => prev + 1);
    }
  };

  const previous = () => {
    if (active === 0) {
      setData((prev) =>
        prev.map((item, i) =>
          i > 0 ? (i === data.length - 1 ? [item[0], 2] : [item[0], 1]) : item
        )
      );
      setActive(data.length - 2);
    } else {
      setData((prev) =>
        prev.map((item, i) => (i > 0 ? (i === active ? [item[0], 2] : [item[0], 1]) : item))
      );
      setActive((prev) => prev - 1);
    }
  };

  return (
    <section className="gpg-map section-bg">
      <Container>
        <Row>
          <Col className="col--no-gutters" xl="8">
            <div className="gpg-map__map-container">
              <MapChart data={data} onSelect={updateState} />
            </div>
            <div ref={infoRef} className="gpg-map__info">
              <div className="gpg-map__info-container">
                <div className="gpg-map__info-header">
                  <h2 className="gpg-map__info-title h5">{countries[active].countryName}</h2>
                  {!countries[active].popupInfoText ? null : (
                    <div
                      className="caption"
                      dangerouslySetInnerHTML={{
                        __html: countries[active].popupInfoText,
                      }}
                    />
                  )}
                </div>
                <div className="gpg-map__info-content">
                  <PopupInformationRenderer
                    items={countries[active].popupInformation}
                    country={countries[active].countryName}
                  />
                </div>
                <button
                  type="button"
                  className="custom-control-arrow control-arrow control-prev"
                  onClick={previous}
                >
                  <span className="sr-only">{nextCountryButtonLabel}</span>
                </button>
                <button
                  type="button"
                  className="custom-control-arrow control-arrow control-next"
                  onClick={next}
                >
                  <span className="sr-only">{previousCountryButtonLabel}</span>
                </button>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="col--no-gutters" xl="8">
            {!bottomText ? null : (
              <div
                className="gpg-map__bottom rte"
                dangerouslySetInnerHTML={{
                  __html: bottomText,
                }}
              />
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default GpgMap;
