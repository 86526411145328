import React, { FC } from 'react';
import classNames from 'classnames';

import { Col, Container, Row } from 'layout';
import LinkItem from 'common/LinkItem';
import { commonColorHandler } from 'utils/colorsHandler';

import { StatisticsBasicProps } from './models';
import StatisticsBasicWithIcons from './StatisticsBasicWithIcons';
import { StatisticsBasicWithIconsProps } from './StatisticsBasicWithIcons/models';
import StatisticsBasicWithNumbers from './StatisticsBasicWithNumbers';
import { StatisticsBasicWithNumbersProps } from './StatisticsBasicWithNumbers/models';

import './StatisticsBasic.scss';

const StatisticsBasic: FC<StatisticsBasicProps> = ({ module }) => (
  <section
    className={classNames('statistics-basic', commonColorHandler(module.stats[0].colorStyle[0]), {
      section:
        !module.stats[0].colorStyle[0] ||
        module.stats[0].colorStyle[0] === 'statistics-basic--white-blue',
    })}
  >
    <Container>
      <Row>
        <Col xl="5" md="12" sm="12" className="statistics-basic__content col--no-gutters rte-white">
          <h2 className="statistics-basic__heading">
            {module.subheading && (
              <span className="statistics-basic__subheading">{module.subheading}</span>
            )}
            <span className="h4">{module.heading}</span>
          </h2>
          <div
            className="statistics-basic__rte rte"
            dangerouslySetInnerHTML={{ __html: module.description }}
          />
          <LinkItem
            link={module.link}
            linkClass="statistics-basic__link btn btn--pink"
            titleClass="statistics-basic__link-text"
            hasIcon
            isLink
            showTitle
          />
        </Col>
        <Col
          xl="7"
          md="12"
          sm="12"
          className={classNames('statistics-basic__stats col--no-gutters', {
            'rte-white':
              commonColorHandler(module.stats[0].colorStyle[0]) === '' ||
              commonColorHandler(module.stats[0].colorStyle[0]) === 'statistics-basic--white-blue',
            'rte-light-grey':
              commonColorHandler(module.stats[0].colorStyle[0]) === 'statistics-basic--grey-energy',
            'rte-black':
              commonColorHandler(module.stats[0].colorStyle[0]) ===
              'statistics-basic--black-energy',
            'rte-pink-gradient':
              commonColorHandler(module.stats[0].colorStyle[0]) ===
              'statistics-basic--energy-white',
          })}
        >
          {module.stats[0].__typename === 'StatisticsBasicWithNumbers' ? (
            <StatisticsBasicWithNumbers {...(module.stats[0] as StatisticsBasicWithNumbersProps)} />
          ) : (
            <StatisticsBasicWithIcons {...(module.stats[0] as StatisticsBasicWithIconsProps)} />
          )}
        </Col>
      </Row>
    </Container>
  </section>
);

export default StatisticsBasic;
