import React, { FC } from 'react';
import classNames from 'classnames';

import { Col, Container, Row } from 'layout';
import Image from 'common/Image';
import LinkItem from 'common/LinkItem';

import BoxText from './BoxText';
import { AwardsLogoBoxesProps } from './models';

import './AwardsLogoBoxes.scss';

const AwardsLogoBoxes: FC<AwardsLogoBoxesProps> = ({ module }) => (
  <section className="section awards-logo-boxes">
    <Container>
      <Row element="ul" className="awards-logo-boxes__list">
        {module.boxes?.map((box) => (
          <Col
            element="li"
            xl="3"
            md="4"
            sm="12"
            key={box.logo.altText}
            className="col--no-gutters awards-logo-boxes__item"
          >
            <div
              className={classNames('awards-logo-boxes__item-holder', {
                'awards-logo-boxes__item-holder--center': !box.text,
              })}
            >
              <Image
                className="awards-logo-boxes__image"
                imageData={box.logo}
                alt={box.logo.altText}
                objectFit="contain"
              />
              <BoxText text={box.text} />
              <LinkItem
                linkClass="awards-logo-boxes__link"
                link={box.ctaLink}
                title=" "
                ariaLabel={box.ctaLink[0]?.name}
                hasIcon={false}
                isLink
                showTitle={false}
              />
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  </section>
);

export default AwardsLogoBoxes;
