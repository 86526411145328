import React, { FC } from 'react';

import { Col, Row } from 'layout';

import { StatisticsBasicWithNumbersProps } from './models';

import './StatisticsBasicWithNumbers.scss';

const StatisticsBasicWithNumbers: FC<StatisticsBasicWithNumbersProps> = ({ stats }) => (
  <Row className="statistics-basic__stats-holder">
    {stats?.map(({ statNumber, statTitle, statDescription }) => (
      <Col
        xl="6"
        md="6"
        sm="12"
        key={statNumber}
        className="statistics-basic__stats-item col--no-gutters"
      >
        <h3 className="statistics-basic__stats-heading">
          <div
            className="statistics-basic__stats-number rte"
            dangerouslySetInnerHTML={{ __html: statNumber }}
          />
          {statTitle && <span className="statistics-basic__stats-title h6">{statTitle}</span>}
        </h3>

        {statDescription && (
          <div
            className="statistics-basic__stats-rte"
            dangerouslySetInnerHTML={{ __html: statDescription }}
          />
        )}
      </Col>
    ))}
  </Row>
);

export default StatisticsBasicWithNumbers;
