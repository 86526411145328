import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import classNames from 'classnames';
import _, { debounce } from 'lodash';

import { Col, Container, Row } from 'layout';
import CustomDropdown from 'common/CustomDropdown/CustomDropdown';
import Image from 'common/Image';
import ArticlePreviewTimelineImage from 'common/images/ArticlePreviewTimelineImage';
import LinkItem from 'common/LinkItem';
import { isBrowser } from 'utils/browser';
import { DESKTOP_DEVICE, TABLET_DEVICE, XLDESKTOP_DEVICE } from 'utils/constants';

import Arrow from '../../common/icons/arrow-right.svg';
import { TimelineProps } from './models';
import { flattenArray } from './util';

import './Timeline.scss';

const Timeline: FC<TimelineProps> = ({ module }) => {
  const { years, yearText, skipSectionText, previousButtonText, nextButtonText } = module;
  const [screen, setScreen] = useState<number>(0);
  const [activeYear, setActiveYear] = useState<number>(years[0].year);
  const [slideRef, setSlideRef] = useState<number[]>(flattenArray(years));
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [clicked, setClicked] = useState(false);
  const timelineRef = useRef<HTMLDivElement>(null);
  const yearLastRef = useRef<HTMLDivElement[]>([]);
  const timerRef = useRef<any[]>([]);
  const indicatorRef = useRef<HTMLDivElement>(null);
  const heightMultiply = years.length <= 5 ? years.length / 10 : 0.95;

  const getFirstIndex = (year) => slideRef.indexOf(year);

  const handleArrowClick = (time) => {
    setClicked(true);

    if (timerRef.current[0]) clearInterval(timerRef.current[0]);

    timerRef.current[0] = setInterval(() => {
      setClicked(false);

      clearInterval(timerRef.current[0]);
    }, time);
  };

  const handleScroll = useCallback(() => {
    if (!timelineRef.current) return;
    const viewportPosition = window.scrollY;
    const timelinePosition = timelineRef.current.offsetTop;
    const timelineHeight = timelineRef.current.offsetHeight;
    const numberOfYears = years.length;
    const activeYearIndex = years.findIndex((year) => year.year === activeYear);
    const yearHeight = (timelineHeight / numberOfYears) * heightMultiply;
    const newActiveYearIndex = Math.floor((viewportPosition - timelinePosition) / yearHeight);
    if (indicatorRef.current)
      indicatorRef.current.style.setProperty(
        '--height',
        `${
          (viewportPosition - timelinePosition) / (timelineHeight - 1200) <= 0
            ? 0
            : (viewportPosition - timelinePosition) / (timelineHeight - 1200) >= 1
            ? 1
            : (viewportPosition - timelinePosition) / (timelineHeight - 1200)
        }`
      );

    if (
      newActiveYearIndex !== activeYearIndex &&
      newActiveYearIndex >= 0 &&
      newActiveYearIndex < slideRef.length - 1
    ) {
      if (!clicked) {
        if (newActiveYearIndex < numberOfYears) {
          setActiveYear(years[newActiveYearIndex].year);
        }
        if (newActiveYearIndex < activeYearIndex) {
          handleArrowClick(800);
          setCurrentSlide((prevState) => (prevState > 0 ? prevState - 1 : prevState));
        } else {
          handleArrowClick(800);
          setCurrentSlide((prevState) =>
            prevState < slideRef.length - 1 ? prevState + 1 : prevState
          );
        }
      }
    }
    if (activeYearIndex >= Math.floor(numberOfYears / 2)) {
      (
        document.querySelector('.timeline__controlls-skip--next') as HTMLButtonElement
      ).style.display = 'none';
      (
        document.querySelector('.timeline__controlls-skip--prev') as HTMLButtonElement
      ).style.display = 'block';
    } else {
      (
        document.querySelector('.timeline__controlls-skip--next') as HTMLButtonElement
      ).style.display = 'block';
      (
        document.querySelector('.timeline__controlls-skip--prev') as HTMLButtonElement
      ).style.display = 'none';
    }
  }, [activeYear, slideRef, clicked]);

  useEffect(() => {
    setSlideRef(flattenArray(years));
  }, [years]);

  useEffect(() => {
    setCurrentOffset(0);

    if (activeYear === years[0].year) return;
    setCurrentOffset(
      yearLastRef.current[years.findIndex((year) => year.year === activeYear)]?.offsetLeft
    );
  }, [activeYear]);

  useEffect(() => {
    screen >= DESKTOP_DEVICE
      ? window.addEventListener('scroll', handleScroll)
      : window.removeEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [activeYear, slideRef, clicked, screen]);

  const next = () => {
    handleArrowClick(1000);
    setCurrentSlide((prevState) => (prevState < slideRef.length - 1 ? prevState + 1 : prevState));
  };

  const prev = () => {
    handleArrowClick(1000);
    setCurrentSlide((prevState) => (prevState > 0 ? prevState - 1 : prevState));
  };

  const handleChange = (index) => {
    if (slideRef[index] !== activeYear) {
      if (!timelineRef.current) return;

      if (screen >= DESKTOP_DEVICE) {
        window.scrollTo({
          top:
            timelineRef.current.offsetTop +
            years.findIndex((year) => year.year === slideRef[index]) *
              ((timelineRef.current.offsetHeight / years.length) * heightMultiply),
        });
      }
      setActiveYear(slideRef[index]);
    }
  };

  const handleResize = debounce(() => {
    setScreen(window.innerWidth);
  }, 500);

  useEffect(() => {
    if (isBrowser()) {
      setScreen(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleSelect = (year) => {
    handleArrowClick(1500);
    setCurrentSlide(getFirstIndex(year));
  };

  const skipToFirstYear = () => {
    handleArrowClick(1500);
    setCurrentSlide(0);
  };

  const skipToLastYear = () => {
    handleArrowClick(1500);
    setCurrentSlide(slideRef.length - 1);
  };

  const slides = useMemo(
    () =>
      _.flattenDeep(
        years?.map(({ year, items }) =>
          items?.map((item) =>
            item?.items.map(({ title, summaryText, image, link }, index) => (
              <div className="timeline__slide" key={`${year}-${title}`}>
                {index === 0 ? (
                  <h2
                    className={classNames('timeline__slide-year', {
                      h6: screen >= DESKTOP_DEVICE,
                      tag: screen < DESKTOP_DEVICE,
                    })}
                  >
                    {year}
                  </h2>
                ) : null}
                {image && screen >= DESKTOP_DEVICE ? (
                  item.__typename === 'TimelineNestedItems' ? (
                    <Image
                      imageData={image}
                      alt={image.altText}
                      className="timeline__slide-image"
                    />
                  ) : (
                    <ArticlePreviewTimelineImage
                      imageData={image}
                      alt={image.altText}
                      className="timeline__slide-image"
                    />
                  )
                ) : null}
                <h3 className="timeline__slide-title h4">{title}</h3>
                <p className="timeline__slide-description caption">{summaryText}</p>
                <LinkItem
                  link={link}
                  linkClass="timeline__slide-link link link--black-white"
                  hasIcon
                  isLink
                  showTitle
                />
              </div>
            ))
          )
        )
      ),
    [screen]
  );

  return (
    <section ref={timelineRef} className="timeline">
      <Container>
        <Row>
          <Col md="6" xl="9" className="timeline__controlls col--no-gutters">
            <CustomDropdown
              label={yearText}
              items={years.map((year) => year.year)}
              onSelect={(item) => handleSelect(item)}
              selectedItem={activeYear}
            />
            <button
              className="timeline__controlls-skip timeline__controlls-skip--next link link--download"
              onClick={() => skipToLastYear()}
              type="button"
            >
              {skipSectionText}
              <Arrow className="our-brands-scroller__icon-skip" aria-hidden="true" />
            </button>
          </Col>
          <Col xl="4" className="timeline__years col--no-gutters">
            <div
              className="timeline__container"
              style={{ height: screen >= DESKTOP_DEVICE ? years.length * 600 + 320 : '' }}
            >
              <div className="timeline__content">
                <div className="timeline__year-container">
                  <div className="timeline__year-first">
                    <span>{activeYear.toString().slice(0, 2)}</span>
                  </div>
                  <div
                    className="timeline__year-last-container"
                    style={{
                      transform:
                        screen >= XLDESKTOP_DEVICE
                          ? `translateY(-${
                              years.findIndex((year) => year.year === activeYear) * 162
                            }px)`
                          : screen >= DESKTOP_DEVICE
                          ? `translateY(-${
                              years.findIndex((year) => year.year === activeYear) * 115.38
                            }px)`
                          : `translateX(-${currentOffset}px)`,
                    }}
                  >
                    {years?.map(({ year }, i) => (
                      <div
                        key={year}
                        ref={(yearLast: HTMLDivElement) => {
                          yearLastRef.current[i] = yearLast;
                        }}
                        className={classNames('timeline__year-last', {
                          active: year === activeYear,
                        })}
                      >
                        {year.toString().slice(2, 4)}
                      </div>
                    ))}
                  </div>
                  <div
                    ref={indicatorRef}
                    className="timeline__indicator"
                    style={{ '--height': 0 } as React.CSSProperties}
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col className="timeline__articles col--no-gutters">
            <div
              className="timeline__container"
              style={{ height: screen >= DESKTOP_DEVICE ? years.length * 600 + 320 : '' }}
            >
              <div className="timeline__content">
                {screen < TABLET_DEVICE ? null : (
                  <>
                    <button
                      type="button"
                      className="timeline__arrow custom-control-arrow control-arrow control-prev"
                      onClick={prev}
                    >
                      <span className="breadcrumb">{previousButtonText}</span>
                    </button>
                    <button
                      type="button"
                      className="timeline__arrow custom-control-arrow control-arrow control-next"
                      onClick={next}
                    >
                      <span className="breadcrumb">{nextButtonText}</span>
                    </button>
                  </>
                )}
                <Carousel
                  infiniteLoop={false}
                  showArrows={false}
                  showStatus={false}
                  showThumbs={false}
                  showIndicators={false}
                  onChange={handleChange}
                  selectedItem={currentSlide}
                  centerMode
                  centerSlidePercentage={screen >= DESKTOP_DEVICE ? 84 : 33}
                  swipeable={screen < DESKTOP_DEVICE}
                >
                  {slides}
                </Carousel>
              </div>
            </div>
          </Col>
          <button
            className="timeline__controlls-skip timeline__controlls-skip--prev link link--download"
            onClick={() => skipToFirstYear()}
            type="button"
          >
            {skipSectionText}
            <Arrow className="our-brands-scroller__icon-skip" aria-hidden="true" />
          </button>
        </Row>
      </Container>
    </section>
  );
};

export default Timeline;
