import React, { FC } from 'react';
import classNames from 'classnames';

import { Row } from 'layout';

import Arrow from '../../icons/arrow-right.svg';
import { ButtonGridProps } from './models';

const ButtonGrid: FC<ButtonGridProps> = ({
  activeItem,
  itemList,
  baseClass,
  handleKeyPress,
  handleBlur,
  handleMouseClick,
  handleToggleInfo,
}) => (
  <Row>
    {itemList.map((listItem, colIndex) => (
      <ul className="col--no-gutters col--sm6 col--md3 col--xl2" key={`col-${colIndex}`}>
        {listItem.map((item, btnIndex) => (
          <li key={item.id}>
            <button
              type="button"
              className={classNames(`${baseClass}-btn link`, {
                'link--black-white': !item.active,
                'link--pink': item.active,
              })}
              key={item.id}
              onClick={() => {
                if (handleToggleInfo && handleMouseClick) {
                  handleToggleInfo(item.id);
                  handleMouseClick(colIndex, btnIndex);
                }
              }}
              tabIndex={colIndex === activeItem?.col && btnIndex === activeItem?.row ? 0 : -1}
              onKeyDown={handleKeyPress}
              onBlur={handleBlur}
              data-testid={item.name}
            >
              <span>{item.name}</span>
              <span aria-hidden="true">
                <Arrow className={`${baseClass}-icon`} />
              </span>
            </button>
          </li>
        ))}
      </ul>
    ))}
  </Row>
);

export default ButtonGrid;
