import React, { FC } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import classNames from 'classnames';

import { Col, Container, Row } from 'layout';
import NewsGrid from 'common/NewsGrid/NewsGrid';
import { commonColorHandler } from 'utils/colorsHandler';

import { NewsAndStoriesProps } from './models';

import './NewsAndStories.scss';

const NewsAndStories: FC<NewsAndStoriesProps> = ({ module }) => {
  const {
    commonFields: { articleReadingTimeText, articleWrittenByText },
  } = useStaticQuery(graphql`
    {
      commonFields {
        articleReadingTimeText
        articleWrittenByText
        pulledArticlesNoResultsText
      }
    }
  `);

  const { backgroundColor, sectionTitle, readMoreButton, articles } = module;

  return (
    <section
      className={classNames(`news-and-stories ${commonColorHandler(backgroundColor[0])}`, {
        section: commonColorHandler(backgroundColor[0]) === 'news-and-stories--white',
        'section-bg': commonColorHandler(backgroundColor[0]) === '',
      })}
    >
      <Container>
        <Row>
          <Col>
            <h2 className="news-and-stories__title h2">{sectionTitle}</h2>
          </Col>
        </Row>
        <Row>
          <NewsGrid
            sortedArticles={articles}
            numberOfCol={2}
            numberOfColTablet={2}
            lang={module.lang}
            readingTimeText={articleReadingTimeText}
            writtenByText={articleWrittenByText}
            hasTitle={false}
          />
        </Row>
        <Row>
          <Col xl="10" md="10" sm="10" className="news-and-stories__col-show-more col--no-gutters">
            <Link className="news-and-stories__more-btn btn btn--pink" to={readMoreButton[0].url}>
              {readMoreButton[0].name}
            </Link>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default NewsAndStories;
