import React, { FC } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import objectHash from 'object-hash';

import Image from 'common/Image';

import InfoBlock from '../InfoBlock';
import { BlocksProps } from './models';

import './Blocks.scss';

const Blocks: FC<BlocksProps> = ({ activeBlock }) => (
  <div
    id={`${objectHash({ switchId: activeBlock.title })}-block`}
    className="switcher-block__blocks-container"
    aria-labelledby={`${activeBlock.title}-block`}
  >
    <AnimatePresence>
      <motion.div
        key={activeBlock.title}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
        className="switcher-block__blocks-item"
      >
        <div className="switcher-block__block-content">
          <Image
            className="switcher-block__block-image"
            imageData={activeBlock.image}
            alt={activeBlock.image.altText}
          />
          <motion.div
            key={activeBlock.title}
            initial={{ y: 60, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 60, opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <InfoBlock infoBlock={activeBlock.infoBlock} />
          </motion.div>
        </div>
      </motion.div>
    </AnimatePresence>
  </div>
);

export default Blocks;
