import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'gatsby';
import { AnimatePresence, motion } from 'framer-motion';

import { Col, Row } from 'layout';
import { DESKTOP_DEVICE, TABLET_DEVICE } from 'utils/constants';

import ScreenRecognitionContext from '../../../../contexts/screenRecognitionContext';
import OurBrandsScrollerDesktopAndTabletBox from '../OurBrandsScrollerDesktopAndTabletBox';
import OurBrandsScrollerMobileBox from '../OurBrandsScrollerMobileBox';
import { OurBrandsScrollerBrandProps } from './models';

import './OurBrandsScrollerBrand.scss';

const OurBrandsScrollerBrand: FC<OurBrandsScrollerBrandProps> = ({
  brand,
  brands,
  activeBrand,
  screen,
  readMoreText,
  ourBrandsTagText,
  closeButtonText,
  brandPageLinkText,
  activeFilter,
  brandHoveredHandle,
  onChangeActiveBrandHandle,
}) => {
  const [showSpan, setShowSpan] = useState<boolean>(false);
  const linkRef = useRef<HTMLSpanElement>(null);
  const titleRef = useRef<any>(null);

  const { isDesktop, isLargeDesktop, isMobile, isSmallMobile } =
    useContext(ScreenRecognitionContext);

  let linksArr;

  const showReadMoreHandle = useCallback(() => {
    linksArr.forEach((link) => link.classList.remove('our-brands-scroller__brand-title--active'));

    setShowSpan(true);

    titleRef.current?.classList.add('our-brands-scroller__brand-title--active');
    linksArr.forEach((link) => link.classList.add('our-brands-scroller__brand-title--hover'));

    brandHoveredHandle(titleRef.current?.dataset.value);

    // accessibility
    if (screen && screen < DESKTOP_DEVICE) {
      titleRef.current.setAttribute('aria-expanded', 'true');
    }
  }, [screen, linksArr, activeFilter]);

  const hideReadMoreHandle = useCallback(
    (e) => {
      if (screen && screen >= DESKTOP_DEVICE) {
        setShowSpan(false);

        titleRef.current?.classList.remove('our-brands-scroller__brand-title--active');
        linksArr.forEach((link) =>
          link.classList.remove('our-brands-scroller__brand-title--hover')
        );
        brandHoveredHandle('');
      }

      if (screen && screen < DESKTOP_DEVICE && screen >= TABLET_DEVICE) {
        brandHoveredHandle('');
        if (e.target === titleRef.current) {
          titleRef.current?.classList.remove('our-brands-scroller__brand-title--active');
        }
      }

      // accessibility
      if (screen && screen < DESKTOP_DEVICE) {
        titleRef.current.setAttribute('aria-expanded', 'false');
      }
    },
    [screen, linksArr, activeFilter]
  );

  const changeActiveBrandHandle = (mobileBrand: undefined) => {
    onChangeActiveBrandHandle(mobileBrand);
  };

  useEffect(() => {
    const links = document.querySelectorAll('.our-brands-scroller__brand-title');

    // Convert links NodeList to an array
    linksArr = Array.prototype.slice.call(links);

    if (screen && screen <= TABLET_DEVICE) {
      if (!activeBrand) {
        linksArr.forEach((link) =>
          link.classList.remove('our-brands-scroller__brand-title--active')
        );
      }
    }
  }, [screen, activeBrand, activeFilter]);

  return (
    <Row className="our-brands-scroller__brands-row">
      <Col xl="9" md="11" sm="12" className="col--no-gutters">
        <div className="our-brands-scroller__brand">
          <div
            className="our-brands-scroller__brand-wrap"
            onMouseEnter={showReadMoreHandle}
            onMouseLeave={hideReadMoreHandle}
          >
            {isDesktop || isLargeDesktop ? (
              <Link
                to={brand.brandPage[0].url}
                className="our-brands-scroller__brand-title h3"
                ref={titleRef}
                data-value={brand.name}
                onFocus={showReadMoreHandle}
                onBlur={hideReadMoreHandle}
              >
                {brand.name}
                <AnimatePresence>
                  {showSpan ? (
                    <motion.span
                      initial={{ x: -15, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      exit={{ x: -15, opacity: 0 }}
                      transition={{ duration: 0.3 }}
                      ref={linkRef}
                      className="our-brands-scroller__brand-read tag"
                    >
                      {readMoreText}
                    </motion.span>
                  ) : null}
                </AnimatePresence>
              </Link>
            ) : (
              <button
                className="our-brands-scroller__brand-title h3"
                onClick={showReadMoreHandle}
                ref={titleRef}
                type="button"
                data-value={brand.name}
                aria-expanded="false"
              >
                {brand.name}
              </button>
            )}
          </div>
        </div>
      </Col>
      <AnimatePresence>
        {brand.name === activeBrand?.name && !isMobile && !isSmallMobile ? (
          <motion.div
            key={brand.name}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            className="our-brands-scroller__card-wrap"
          >
            <OurBrandsScrollerDesktopAndTabletBox
              activeBrand={activeBrand}
              ourBrandsTagText={ourBrandsTagText}
              closeButtonText={closeButtonText}
              brandPageLinkText={brandPageLinkText}
            />
          </motion.div>
        ) : null}
      </AnimatePresence>
      {(brand.name === activeBrand?.name && (isMobile || isSmallMobile)) || !activeBrand ? (
        <Col sm="12" className="col--no-gutters">
          <OurBrandsScrollerMobileBox
            activeBrand={activeBrand}
            brands={brands}
            ourBrandsTagText={ourBrandsTagText}
            closeButtonText={closeButtonText}
            brandPageLinkText={brandPageLinkText}
            changeActiveBrandHandle={changeActiveBrandHandle}
          />
        </Col>
      ) : null}
    </Row>
  );
};

export default OurBrandsScrollerBrand;
