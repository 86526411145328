import React, { FC } from 'react';

import { FactsStatsProps } from './models';

import './FactsStats.scss';

const FactsStats: FC<FactsStatsProps> = ({ fact, text }) => (
  <div className="facts-with-tabs__stat">
    <h3 className="facts-with-tabs__title">
      <span className="facts-with-tabs__stat-fact">{fact}</span>
      <div className="rte facts-with-tabs__stat-text" dangerouslySetInnerHTML={{ __html: text }} />
    </h3>
  </div>
);

export default FactsStats;
