import React, { FC } from 'react';

import { BoxTextProps } from './models';

import './BoxText.scss';

const BoxText: FC<BoxTextProps> = ({ text }) => {
  if (!text) return null;

  return <p className="awards-logo-boxes__text">{text}</p>;
};

export default BoxText;
