import React, { FC } from 'react';

import LinkItem from 'common/LinkItem';

import { MapTextProps } from './models';

import './MapText.scss';

const MapText: FC<MapTextProps> = ({ text, link }) => (
  <div className="gpg-map__info-item">
    <div
      className="gpg-map__info-text rte"
      dangerouslySetInnerHTML={{
        __html: text,
      }}
    />
    <LinkItem link={link} linkClass="gpg-map__info-link link link--pink" hasIcon isLink showTitle />
  </div>
);

export default MapText;
