import React, { FC } from 'react';

import { cutUrl } from 'utils/functions';

import { BenefitsWithIconsProps } from './models';

import './BenefitsWithIcons.scss';

const BenefitsWithIcons: FC<BenefitsWithIconsProps> = ({ items }) => (
  <div className="benefits__with-icons">
    {items?.map(({ icon, iconAltText, title, text }) => {
      const url = cutUrl(icon.url);

      return (
        <div className="benefits__item" key={title}>
          <img src={url} className="benefits__icon" alt={iconAltText} />
          <div className="benefits__item-inner">
            <h3 className="benefits__item-heading h4">{title}</h3>
            <p className="benefits__item-text body">{text}</p>
          </div>
        </div>
      );
    })}
  </div>
);

export default BenefitsWithIcons;
