import React, { FC, useContext } from 'react';
import classNames from 'classnames';

import { ALL } from 'utils/constants';

import Check from '../../../common/icons/check.svg';
import UnCheck from '../../../common/icons/uncheck.svg';
import ScreenRecognitionContext from '../../../contexts/screenRecognitionContext';
import { OurBrandsScrollerFiltersProps } from './models';

import './OurBrandsScrollerFilters.scss';

const OurBrandsScrollerFilters: FC<OurBrandsScrollerFiltersProps> = ({
  activeFilter,
  filterAll,
  filtersName,
  filters,
  filterBrandsHandle,
}) => {
  const { isSmallMobile, isMobile } = useContext(ScreenRecognitionContext);

  return (
    <>
      <ul className="our-brands__tabs" role="tablist">
        <li className="our-brands__tab" role="presentation">
          <span className="our-brands-scroller__filters-type tag">{filtersName}</span>
        </li>
        <li className="our-brands__tab" role="presentation">
          {!isSmallMobile && !isMobile ? (
            <button
              className={classNames('our-brands-scroller__link link link--black-white')}
              onClick={filterBrandsHandle}
              type="button"
              data-value={ALL}
              role="tab"
              aria-selected={activeFilter === ALL}
              aria-controls="our-brands-list"
            >
              {filterAll}
              {activeFilter === ALL ? (
                <Check className="our-brands-scroller__icon" />
              ) : (
                <UnCheck className="our-brands-scroller__icon our-brands-scroller__icon--uncheck" />
              )}
            </button>
          ) : null}
          {filters.map((filter) => (
            <button
              className={classNames('our-brands-scroller__link link link--black-white')}
              onClick={filterBrandsHandle}
              type="button"
              role="tab"
              data-value={filter.name}
              key={filter.name}
              aria-selected={activeFilter === filter.name}
              aria-controls="our-brands-list"
            >
              {filter.tagName}
              {activeFilter === filter.name ? (
                <Check className="our-brands-scroller__icon" />
              ) : (
                <UnCheck className="our-brands-scroller__icon our-brands-scroller__icon--uncheck" />
              )}
            </button>
          ))}
        </li>
      </ul>
    </>
  );
};

export default OurBrandsScrollerFilters;
