import React, { FC } from 'react';
import classNames from 'classnames';

import { Col, Container, Row } from 'layout';
import LinkItem from 'common/LinkItem';
import { commonColorHandler } from 'utils/colorsHandler';

import BenefitsSimpleItems from './BenefitsSimpleItems';
import { BenefitsSimpleItemsProps } from './BenefitsSimpleItems/models';
import BenefitsWithIcons from './BenefitsWithIcons';
import { BenefitsWithIconsProps } from './BenefitsWithIcons/models';
import { BenefitsProps } from './models';

import './Benefits.scss';

const Benefits: FC<BenefitsProps> = ({ module }) => (
  <section
    className={classNames(`benefits ${commonColorHandler(module.backgroundColor[0])}`, {
      section: !commonColorHandler(module.backgroundColor[0]),
      'section-bg': !!commonColorHandler(module.backgroundColor[0]),
    })}
  >
    <Container>
      <Row className="benefits__inner">
        <Col sm="12" md="6" xl="6" className="col--no-gutters">
          <div className="benefits__section">
            <h2 className="benefits__section-title h2">{module.title}</h2>
            <p className="benefits__section-text body">{module.description}</p>
            <LinkItem
              link={module.ctaLink}
              linkClass="benefits__section-link btn btn--pink"
              hasIcon
              isLink
              showTitle
            />
          </div>
        </Col>
        <Col sm="12" md="6" xl="6" className="col--no-gutters">
          <div className="benefits__items">
            {Object.prototype.hasOwnProperty.call(module.items[0], 'hideNumbers') ? (
              <BenefitsSimpleItems {...(module.items[0] as BenefitsSimpleItemsProps)} />
            ) : (
              <BenefitsWithIcons {...(module.items[0] as BenefitsWithIconsProps)} />
            )}
          </div>
        </Col>
      </Row>
    </Container>
  </section>
);
export default Benefits;
